//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside'
// import Subheader from '@/components/SubHeader'
import InlineIcon from '~/assets/northbank_logo.svg?inline'
import Social from '@/components/modules/Social'

export default {
	components: {
		InlineIcon,
		Social,
	},
	directives: {
		ClickOutside,
	},
	props: {
		alt: {
			type: Boolean,
			default: null,
		},
		data: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			ScrollTop: false,
			ScrollDirectionUp: false,
			scrollPos: 0,
			ele: '---',
			showNav: false,
			showMenu: {
				desktop: true,
				mobile: true,
			},
			hideSubHeader: false,
			keywords: '',
		}
	},
	computed: {
		navigation() {
			return this.$store.getters.cmsdata.navigation
		},
		mobilenavigation() {
			return this.$store.getters.cmsdata.mobilenavigation
		},
		InlineIcon() {
			return InlineIcon
		},
		popActive() {
			return this.$store.state.popactive
		},
		searchPages() {
			const pages = this.$store.getters.pages;
			if (this.keywords) {
				const finalpages = Object.entries(pages).filter((page) => {
					return page[1].title.toLowerCase().includes(this.keywords.toLowerCase())
				});
				return (finalpages.length ? Object.fromEntries(finalpages) : false);
			} else {
				return false 
			}
		},
		searchNews() {
			const posts = this.$store.getters.posts
			if (this.keywords) {
				const finalposts = Object.entries(posts).filter((post) => {
					return post[1].Title.toLowerCase().includes(this.keywords.toLowerCase()) || post[1].Description.toLowerCase().includes(this.keywords.toLowerCase())
				})
				return (finalposts.length ? Object.fromEntries(finalposts) : false);
			} else {
				return false 
			}
		},
		searchProjects() {
			const projects = this.$store.getters.projects
			if (this.keywords) {
				const finalprojects = Object.entries(projects).filter((project) => {
					return project[1].title.toLowerCase().includes(this.keywords.toLowerCase()) || project[1].Description.toLowerCase().includes(this.keywords.toLowerCase())
				})
				return (finalprojects.length ? Object.fromEntries(finalprojects) : false);
			} else {
				return false 
			}
		},
	},
	watch: {
		$route(to, from) {
			this.ele.removeEventListener('scroll', this.handleScroll)
			this.ScrollDirectionUp = false
			this.ScrollTop = false
			setTimeout(() => {
				this.ele = document.querySelector('.page')
				if (process.browser && this.ele) {
					this.ele.addEventListener('scroll', this.handleScroll)
				}
			}, 2000)
		},
	},
	mounted() {
		setTimeout(() => {
			this.ele = document.querySelector('.page')
			// console.log('mounted,', this.ele)
			if (process.browser && this.ele) {
				this.ele.addEventListener('scroll', this.handleScroll)
			}
		}, 0)
	},
	methods: {
		onSearch(event) {
			console.log('search: ', event)
			try {
				// this.$strapi.find('pages', {slug: url})
				this.$strapi
					.query('projects')
					.search({ _q: 'my search query' })
					.then(function (response) {
						console.log('getData: ', response)
						if (response !== undefined && response.length > 0) {
							// use data
						} else {
							console.log('error')
						}
					})
			} catch (error) {
				console.log('error')
			}
		},
		resetSearch() {
			this.keywords = ''
		},
		toggleNav() {
			setTimeout(() => {
				this.showNav = !this.showNav
			}, 50)
		},
		closeNav() {
			if (this.showNav) {
				this.showNav = false
			}
			this.keywords = ''
		},
		handleScroll() {
			const scrollTop = Math.max(this.ele.scrollTop)
			// sets hide nav distance
			if (scrollTop >= 100) {
				this.ScrollTop = true
			} else {
				this.ScrollTop = false
			}
			// direction detection
			if (scrollTop > this.scrollPos) {
				this.ScrollDirectionUp = false
			} else {
				this.ScrollDirectionUp = true
			}
			this.scrollPos = scrollTop
		},
	},

	destoyed() {
		// console.log(`NAV - destoyed`)
		this.ele.removeEventListener('scroll', this.handleScroll)
	},
}
