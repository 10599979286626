import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=7012ba39&scoped=true&"
import script from "./AppHeader.vue?vue&type=script&lang=js&"
export * from "./AppHeader.vue?vue&type=script&lang=js&"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=7012ba39&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7012ba39",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Social: require('/home/northbankcm/public_html/frontend/components/modules/Social.vue').default})
