//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconFacebook from '~/assets/icons/facebook.svg?inline'
import IconLinkedin from '~/assets/icons/linkedin.svg?inline'
import IconTwitter from '~/assets/icons/twitter.svg?inline'
import IconInstagram from '~/assets/icons/instagram.svg?inline'

export default {
	components: {
		IconFacebook,
		IconLinkedin,
		IconTwitter,
		IconInstagram
	},
}
